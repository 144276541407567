import React from 'react';
import './Hero.scss';
import HeroVideo from '../HeroVideo/HeroVideo';
import HeroCaption from '../HeroCaption/HeroCaption';

function Hero() {
  return (
    <>
      <section className="hero__section">
        <HeroCaption />
        <HeroVideo />
      </section>
    </>
  );
}

export default Hero;
