import React, { useEffect, useState } from 'react';
import Stepper from './Stepper/Stepper';

function Product({ currentStep, setCurrentStep }) {
  useEffect(() => {
    if (currentStep === 0) {
      console.log('Resetting to step 1');
    }
  }, [currentStep]);
  return (
    <>
      <div className="middle">
        <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
      </div>
    </>
  );
}

export default Product;
