import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ChangeExamplesCard.scss';

function ChangeExamplesCard() {
  const HowWorkArr = [
    {
      title: 'Variant 1',
    },
    {
      title: 'Variant 2',
    },
    {
      title: 'Variant 3',
    },
  ];

  return (
    <>
      <div className="example__listing">
        <Container>
          <Row className="justify-content-center">
            <Col sm={10}>
              <ul className="list-unstyled example__grid">
                <li className="example__card">
                  <div className="main__cont">
                    <div className="exmaple__box">
                      <figure className="thumb"></figure>
                      <figcaption className="caption">
                        <div className="title">Original Video</div>
                      </figcaption>
                    </div>
                  </div>
                  <div className="variant__opt">
                    <ul className="list-unstyled">
                      {HowWorkArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="exmaple__box">
                              <figure className="thumb"></figure>
                              <figcaption className="caption">
                                <div className="title">{item.title}</div>
                              </figcaption>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ChangeExamplesCard;
