import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './HeroCaption.scss';

function HeroCaption() {
  return (
    <>
      <div className="hero__caption" id="home">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12}>
              <h1 className="title">
                Maximize Your Profitable VSL's with <br />
                Effortless Variations—No Editors Needed!
              </h1>
              <p className="desc">
                Unlock the power of iteration and extend the life of your
                winning VSLs by easily changing scenes, <br />
                voiceovers, and background music in minutes, not days.
              </p>

              <div className="btn__grp d-flex flex-wrap align-items-center justify-content-center">
                <Link to="#" className="btn btn-primary">
                  Get started
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HeroCaption;
