import React, { useState } from 'react';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import '../Stepper.scss';
import { Form } from 'react-bootstrap';

function UploadStep({ handleNext }) {
  const [fontSize, setFontSize] = useState(32);
  const [fontColor, setFontColor] = useState('#ffffff');
  const [bgColor, setBgColor] = useState('rgba(0,0,0,0.7)');

  const handleRangeChange = (e) => {
    setFontSize(e.target.value);
  };

  const getRangeBackground = () => {
    const min = 8;
    const max = 72;
    const percentage = ((fontSize - min) / (max - min)) * 100;

    return `linear-gradient(90deg, #bef264 ${percentage}%, #D9D9D9 ${percentage}%)`;
  };

  const handleFontColorChange = (e) => {
    setFontColor(e.target.value);
  };

  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };

  return (
    <>
      <div className="step__cont__row">
        <div className="step__cont__blk">
          <div className="step__card">
            <SectionHeading className="section__heading__sm">
              <Heading>Upload And Customise</Heading>
            </SectionHeading>
            <div className="step__form">
              <div className="fld__grp">
                <Form.Group>
                  <Form.Label>Upload your video:</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Video’s MP3 File:</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Video’s transcribed Text File:</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </div>
              <div className="fld__grp">
                <SectionHeading className="section__heading__sm mb-3 text-start">
                  <Heading>Subtitle Design</Heading>
                </SectionHeading>
                <Form.Group>
                  <Form.Label>Upload Font File: </Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label>
                    Font Size:{' '}
                    <span className="fontsize__value">{fontSize}px</span>
                  </Form.Label>

                  <input
                    type="range"
                    class="input-slider"
                    min={8}
                    max={72}
                    value={fontSize}
                    onChange={handleRangeChange}
                    className="custom__range"
                    style={{
                      background: getRangeBackground(),
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Font Color:</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="color"
                      value={fontColor}
                      onChange={handleFontColorChange}
                    />
                    <p className="color__value">{fontColor}</p>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Subtitles Background Color:</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="color"
                      value={bgColor}
                      onChange={handleBgColorChange}
                    />
                    <p className="color__value">{bgColor}</p>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="step__buttons">
              <button
                className="btn btn-outline-dark w-100"
                onClick={handleNext}
              >
                Process
              </button>
            </div>
          </div>
        </div>

        <div className="step__cont__blk step__cont__rt">
          <div className="tutorial__blk">
            <SectionHeading>
              <Heading>Tutorial</Heading>
            </SectionHeading>

            <div className="tutorial__video"></div>
          </div>
          <div className="tutorial__blk">
            <SectionHeading>
              <Heading>Preview</Heading>
            </SectionHeading>

            <div className="tutorial__video preview__tutorial">
              <p>Your New Scene Will Go Here</p>
              <div
                className="caption"
                style={{
                  backgroundColor: bgColor,
                  fontSize: `${fontSize}px`,
                  color: fontColor,
                }}
              >
                This Is How Your Original Subtitle <br />
                Text Will Be Displayed
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadStep;
