import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import videoScenesIcon from '../../../../../../assets/images/users-round.svg';
import voiceIcon from '../../../../../../assets/images/send.svg';
import musicIcon from '../../../../../../assets/images/workflow.svg';
import './WhyNeedCard.scss';

function WhyNeedCard() {
  const whyNeedArr = [
    {
      icon: videoScenesIcon,
      title: 'Video Scenes',
      desc: 'Keep your audience engaged by updating the visual story.',
    },
    {
      icon: voiceIcon,
      title: 'Voice Over',
      desc: 'Give your message a new life with a different voice.',
    },
    {
      icon: musicIcon,
      title: 'Background Music',
      desc: 'Set a new tone by swapping out the music.',
    },
  ];

  return (
    <>
      <div className="why__listing">
        <Container>
          <Row>
            {whyNeedArr.map((item, index) => {
              return (
                <Col sm={4}>
                  <div className="why__card">
                    <i className="icon">
                      <img src={item.icon} alt="" />
                    </i>
                    <h4 className="title">{item.title}</h4>
                    <p className="desc">{item.desc}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WhyNeedCard;
