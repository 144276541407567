import React, { useState } from 'react';
import UploadStep from './Components/UploadStep';
import SceneChangeStep from './Components/SceneChangeStep';
import VoiceStep from './Components/VoiceStep';
import { Col, Container, Row } from 'react-bootstrap';
import './Stepper.scss';
import MusicChange from './Components/MusicChange';
import Download from './Components/Download';
import LoadingScreen from '../../../GlobalComponents/LoadingScreen/LoadingScreen';

function Stepper({ currentStep, setCurrentStep }) {
  // const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    setTimeout(() => {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000);
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const steps = [
    { title: 'Upload', component: <UploadStep handleNext={handleNext} /> },
    {
      title: 'Scene Change',
      component: (
        <SceneChangeStep handleNext={handleNext} handleBack={handleBack} />
      ),
    },
    {
      title: 'Voice Over Change',
      component: (
        <VoiceStep
          handleNext={handleNext}
          handleBack={handleBack}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      title: 'Background Music Change',
      component: (
        <MusicChange
          handleNext={handleNext}
          handleBack={handleBack}
          setLoading={setLoading}
        />
      ),
    },
    { title: 'Download', component: <Download handleBack={handleBack} /> },
  ];

  return (
    <>
      {!loading ? (
        <div className="stepper__section">
          <Container>
            <Row className="justify-content-center">
              <Col xl={10}>
                <ul className="list-unstyled stepper__steps">
                  {steps.map((step, index) => (
                    <li
                      key={index}
                      className={`step ${
                        index === currentStep ? 'active' : ''
                      } ${index < currentStep ? 'finish' : ''}`}
                    >
                      <span className="steps__name">{step.title}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="stepper__content">
                  {steps[currentStep].component}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <LoadingScreen />
      )}

      {/* {loading && <LoadingScreen />} */}
    </>
  );
}

export default Stepper;
