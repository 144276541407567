import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import userSingle from '../../../../assets/images/user-round.svg';
import user2 from '../../../../assets/images/users-2.svg';
import building from '../../../../assets/images/building.svg';
import leftArrow from '../../../../assets/images/left-arrow.svg';
import leftArrowWt from '../../../../assets/images/left-arrow-wt.svg';
import './Pricing.scss';

function Pricing() {
  return (
    <>
      <section className="cmn__section pricing__section" id="pricing">
        <Container>
          <Row>
            <Col sm={6} lg={4}>
              <div className="pricing__card">
                <i className="card__icon">
                  <img src={userSingle} alt="" />
                </i>
                <span className="type">Basic</span>
                <div className="price">
                  $297 <small>per month</small>
                </div>
                <small className="per__text">$14.85/video</small>
                <p className="include">20 New Variations Per Month</p>
                <Link to="/" className="btn btn-outline-primary">
                  Get started
                  <i className="icon">
                    <img src={leftArrow} alt="" />
                  </i>
                </Link>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="pricing__card pricing__card__green">
                <div className="tag">Most popular!</div>
                <i className="card__icon">
                  <img src={user2} alt="" />
                </i>
                <span className="type">Basic</span>
                <div className="price">
                  $297 <small>per month</small>
                </div>
                <small className="per__text">$14.85/video</small>
                <p className="include">20 New Variations Per Month</p>
                <Link to="/" className="btn btn-outline-light">
                  Get started
                  <i className="icon">
                    <img src={leftArrowWt} alt="" />
                  </i>
                </Link>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="pricing__card">
                <i className="card__icon">
                  <img src={building} alt="" />
                </i>
                <span className="type">Basic</span>
                <div className="price">
                  $297 <small>per month</small>
                </div>
                <small className="per__text">$14.85/video</small>
                <p className="include">20 New Variations Per Month</p>
                <Link to="/" className="btn btn-outline-primary">
                  Get started
                  <i className="icon">
                    <img src={leftArrow} alt="" />
                  </i>
                </Link>
              </div>
            </Col>
          </Row>

          <div className="info__text">
            <Container>
              <Row className="justify-content-center">
                <Col sm={10}>
                  <p>
                    At this price, you’ll pay a fraction of what it costs to
                    hire a video editor, and you’ll be able to produce high-
                    <br />
                    quality iterations in a fraction of the time.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Pricing;
