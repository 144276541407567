import React from 'react';
import videoImg from '../../../../assets/images/video-placeholder.jpg';
import './HeroVideo.scss';
import { Col, Container, Row } from 'react-bootstrap';

function HeroVideo() {
  return (
    <>
      <div className="hero__video">
        <Container>
          <Row className="justify-content-center">
            <Col sm={10}>
              <div className="hero__video__thumb">
                <img src={videoImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HeroVideo;
