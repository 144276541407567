import React, { useState } from 'react';
import logo from '../../assets/images/logo-colored.svg';
import eyeIcon from '../../assets/images/eye.svg';

import './Login.scss';
import SectionHeading from '../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../GlobalComponents/SectionHeading/Components/Heading';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const storedEmail = 'demo@example.com';
  const storedPassword = 'demo123';

  const handleLogin = (e) => {
    e.preventDefault();

    if (email === storedEmail && password === storedPassword) {
      localStorage.setItem('isLoggedIn', 'true');
      navigate('/product');
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="login__wrapper">
        <div className="login__blk">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <SectionHeading className="section__heading__xs">
            <Heading>Sign In</Heading>
          </SectionHeading>

          <div className="login__form">
            <Form onSubmit={handleLogin}>
              <Form.Group
                className="mb-4"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Password:</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="view__password"
                    onClick={togglePasswordVisibility}
                  >
                    <img src={eyeIcon} alt="" />
                  </span>
                </div>
              </Form.Group>
              <p className="mb-0">
                <Link to="" className="forgot__link">
                  Forgot password?
                </Link>
              </p>
              <div className="btn__row">
                <Button className="btn btn-primary" type="submit">
                  Sign In
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
