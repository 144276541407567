import React from 'react';
import './Contact.scss';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../GlobalComponents/SectionHeading/Components/Description';

function Contact() {
  return (
    <>
      <section className="cmn__section contact__section" id="contact">
        <SectionHeading>
          <Container>
            <Heading>Contact Us</Heading>
            <Description>
              We’d love to hear from you. Please fill out this form.
            </Description>
          </Container>
        </SectionHeading>

        <div className="contact__blk">
          <Row>
            <Col sm={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>First name</Form.Label>
                <Form.Control type="text" placeholder="First Name" />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Last name</Form.Label>
                <Form.Control type="text" placeholder="Last name" />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="you@company.com" />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5">
            <Button className="btn btn-primary w-100">Send message</Button>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Contact;
