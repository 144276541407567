import React, { useState } from 'react';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import backArrow from '../../../../assets/images/back-btn.svg';
import arrowRightGreen from '../../../../assets/images/arrow-right-green.svg';
import videoPlaceholder from '../../../../assets/images/video-placeholder.jpg';
import plusIcon from '../../../../assets/images/plus.svg';
import { Button, Col, Form, Row } from 'react-bootstrap';

function MusicChange({ handleNext, handleBack, setLoading }) {
  const [mp3Files, setMp3Files] = useState([{ start: '', end: '' }]);

  const addMoreMp3 = () => {
    setMp3Files([...mp3Files, { start: '', end: '' }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMp3Files = [...mp3Files];
    updatedMp3Files[index][field] = value;
    setMp3Files(updatedMp3Files);
  };

  const handleProcess = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      handleNext();
    }, 3000);
  };

  return (
    <>
      <div className="step__form__center music__step">
        <div className="step__card">
          <SectionHeading className="section__heading__sm back__heading mb-5">
            <span className="back__btn" onClick={handleBack}>
              <img src={backArrow} alt="Back" />
            </span>
            <Heading>Select Background Music</Heading>
          </SectionHeading>

          <div className="step__form">
            <div className="music__video__blk">
              <img src={videoPlaceholder} alt="" />
            </div>
            {mp3Files.map((mp3, index) => (
              <div className="fld__grp" key={index}>
                <Form.Group>
                  <Form.Label>{`Upload MP3 ${index + 1}:`}</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-4">
                    What Second Should This MP3 Play From?{' '}
                    <small className="min__lbl">in minutes</small>{' '}
                  </Form.Label>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Start:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="00:00"
                          value={mp3.start}
                          onChange={(e) =>
                            handleInputChange(index, 'start', e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>End:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="00:00"
                          value={mp3.end}
                          onChange={(e) =>
                            handleInputChange(index, 'end', e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </div>
            ))}

            <div className="upload__btn">
              <Button className="btn btn-primary has__arw" onClick={addMoreMp3}>
                <i className="arw__icon">
                  <img src={plusIcon} alt="" />
                </i>
                Upload More MP3
              </Button>
            </div>
          </div>

          <div className="step__buttons d-flex justify-content-end gap-4">
            <Button
              className="btn btn-primary has__arw"
              onClick={handleProcess}
            >
              Process
              <i className="arw__icon">
                <img src={arrowRightGreen} alt="" />
              </i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MusicChange;
