import React, { useState } from 'react';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import backArrow from '../../../../assets/images/back-btn.svg';
import arrowRightGreen from '../../../../assets/images/arrow-right-green.svg';
import { Button, Form, Modal } from 'react-bootstrap';

function VoiceStep({ handleNext, handleBack, setCurrentStep }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [fontSize, setFontSize] = useState(20);
  const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0.5)');

  const handleRangeChange = (e) => {
    setFontSize(e.target.value);
  };

  const getRangeBackground = () => {
    const min = 8;
    const max = 72;
    const percentage = ((fontSize - min) / (max - min)) * 100;

    return `linear-gradient(90deg, #bef264 ${percentage}%, #D9D9D9 ${percentage}%)`;
  };

  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };

  const handleConfirm = () => {
    setCurrentStep(4);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="step__form__center">
        <div className="step__card">
          <SectionHeading className="section__heading__sm back__heading mb-5">
            <span className="back__btn" onClick={handleBack}>
              <img src={backArrow} alt="Back" />
            </span>
            <Heading>Select Your New Voiceover</Heading>
          </SectionHeading>

          <div className="step__form">
            <div className="fld__grp">
              <Form.Group>
                <Form.Label>Eleven Labs API Key:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Eleven Labs API Key "
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>New Voice ID:</Form.Label>
                <Form.Control type="text" placeholder="Enter Voice ID " />
              </Form.Group>
            </div>
            <div className="fld__grp">
              <SectionHeading className="section__heading__sm mb-0 text-start">
                <Heading>Subtitle Design</Heading>
              </SectionHeading>
              <Form.Group>
                <Form.Label>Upload Font File:</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
              <Form.Group>
                <Form.Label>subtitle box colour:</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type="color"
                    value={bgColor}
                    onChange={handleBgColorChange}
                  />
                  <p className="color__value">{bgColor}</p>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Font Size:{' '}
                  <span className="fontsize__value">{fontSize}px</span>
                </Form.Label>

                <input
                  type="range"
                  class="input-slider"
                  min={8}
                  max={72}
                  value={fontSize}
                  onChange={handleRangeChange}
                  className="custom__range"
                  style={{
                    background: getRangeBackground(),
                  }}
                />
              </Form.Group>
            </div>
            <div className="fld__grp">
              <div className="grp">
                <SectionHeading className="section__heading__xxs text-start mb-4">
                  <Heading>Preview:</Heading>
                </SectionHeading>

                <div className="tutorial__video preview__tutorial preview__tutorial__sm voice__preview">
                  <p className="text__sm">
                    Your Selected Video Scene Will Go Here
                  </p>
                  <div
                    className="caption"
                    style={{
                      backgroundColor: bgColor,
                      fontSize: `${fontSize}px`,
                    }}
                  >
                    This Is How Your Original Subtitle <br />
                    Text Will Be Displayed
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step__buttons d-flex justify-content-end gap-4">
            <button className="btn btn-outline-dark" onClick={handleShow}>
              Skip
            </button>
            <button className="btn btn-primary has__arw" onClick={handleNext}>
              Process
              <i className="arw__icon">
                <img src={arrowRightGreen} alt="" />
              </i>
            </button>
          </div>
        </div>
      </div>

      {/* Modal Code */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="confirm__msg">
            By Skipping The Voiceover Change, You Won't Be Able To Change The
            Background Music And Will Be Redirected To Download Your Video With
            The New Scenes.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VoiceStep;
