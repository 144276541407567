import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import appLogo from '../assets/images/logo.svg';
import facebook from '../assets/images/facebook.svg';
import linkedin from '../assets/images/linkedin.svg';
import twitter from '../assets/images/twitter.svg';
import './FooterNew.scss';

function FooterNew() {
  const offset = 80;

  const scrollToSection = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="footer__top">
          <Container>
            <Row className="align-items-center">
              <Col sm={3} className="ftr__col">
                <div className="ftr__logo">
                  <img src={appLogo} alt="App Logo" />
                </div>
              </Col>
              <Col sm={6} className="ftr__col">
                <ul className="ftr__links">
                  <li>
                    <a href="#home" onClick={(e) => scrollToSection(e, 'home')}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#features"
                      onClick={(e) => scrollToSection(e, 'features')}
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="#benefits"
                      onClick={(e) => scrollToSection(e, 'benefits')}
                    >
                      Benefits
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pricing"
                      onClick={(e) => scrollToSection(e, 'pricing')}
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      href="#contact"
                      onClick={(e) => scrollToSection(e, 'contact')}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </Col>
              <Col sm={3} className="ftr__col">
                <div className="social">
                  <a href="/">
                    <img src={facebook} alt="Facebook" />
                  </a>
                  <a href="/">
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                  <a href="/">
                    <img src={twitter} alt="Twitter" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer__btm">
          <Container>
            <p className="copyright">
              © 2021 SceneSwitcher. All rights reserved.
            </p>
          </Container>
        </div>
      </footer>
    </>
  );
}

export default FooterNew;
