import React, { useState } from 'react';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import { Link } from 'react-router-dom';
import backArrow from '../../../../assets/images/back-btn.svg';
import arrowRightGreen from '../../../../assets/images/arrow-right-green.svg';
import videoPlaceholderIcon from '../../../../assets/images/video-placeholder-icon.png';
import { Modal } from 'react-bootstrap';

function SceneChangeStep({ handleNext, handleBack }) {
  const [show, setShow] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProcessClick = () => {
    setIsPreviewVisible(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {!isPreviewVisible ? (
        <div className="step__form__center">
          <div className="step__card">
            <SectionHeading className="section__heading__sm back__heading mb-4">
              <span className="back__btn" onClick={handleBack}>
                <img src={backArrow} alt="Back" />
              </span>
              <Heading>Upload Your New Scenes</Heading>
            </SectionHeading>

            <div className="instruction__blk">
              <h6 className="upload__title ">Instructions:</h6>
              <p className="text">
                - Play the video and pause it at the point you want to replace.
              </p>

              <p className="text">
                - <strong>Hold Shift +</strong> click on the video to choose a
                new video segment to upload and replace the current segment.
              </p>
              <p className="text">
                - <Link onClick={handleShow}>Video tutorial</Link>
              </p>
            </div>

            <div className="uploaded__video">
              <h6 className="upload__title text-center">Video Has Uploaded</h6>

              <div className="uploaded__video__thumb">Video here</div>
            </div>
            <div className="step__buttons">
              <button
                className="btn btn-primary w-100"
                onClick={handleProcessClick}
              >
                Process
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="step__form__center">
          <div className="step__card">
            <SectionHeading className="section__heading__sm back__heading mb-4">
              <span className="back__btn" onClick={handleBack}>
                <img src={backArrow} alt="Back" />
              </span>
              <Heading>Preview your new Video</Heading>
            </SectionHeading>

            <div className="uploaded__video">
              <div className="uploaded__video__thumb">Video here</div>
            </div>
            <div className="step__buttons d-flex justify-content-end gap-4">
              {/* <button className="btn btn-outline-dark" onClick={handleShow}>
                Skip
              </button> */}
              <button className="btn btn-primary has__arw" onClick={handleNext}>
                Process
                <i className="arw__icon">
                  <img src={arrowRightGreen} alt="" />
                </i>
              </button>
            </div>

            {/* <p className="note">
              Please note that when you click Download the watermark will be
              removed
            </p> */}
          </div>
        </div>
      )}

      {/* Modal Code */}
      <Modal
        className="tutorial__modal"
        show={show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal__tutorial__video">
            <img src={videoPlaceholderIcon} alt="" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SceneChangeStep;
