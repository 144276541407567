import React from 'react';
import './SectionHeading.scss';
import classNames from 'classnames';

function SectionHeading({ children, className }) {
  const sectionHeadingClasses = classNames('section__heading', className);

  return (
    <>
      <div className={sectionHeadingClasses}>{children}</div>
    </>
  );
}

export default SectionHeading;
