import React from 'react';
import './ChangeExamples.scss';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import { Container } from 'react-bootstrap';
import HowWorksCard from './Components/ChangeExamplesCard/ChangeExamplesCard';

function ChangeExamples({ headingText, className }) {
  return (
    <>
      <section className={`cmn__section example__section ${className}`}>
        <SectionHeading className="section__heading__line">
          <Container>
            <Heading>
              <span>{headingText}</span>
            </Heading>
          </Container>
        </SectionHeading>

        <HowWorksCard />
      </section>
    </>
  );
}

export default ChangeExamples;
