import React, { useEffect, useState } from 'react';
import appLogo from '../assets/images/logo.svg';
import profileIcon from '../assets/images/user-round-wt.svg';
import humburger from '../assets/images/humburger.svg';
import closeIcon from '../assets/images/close-wt.svg';

import {
  Button,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Row,
} from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.scss';

function Header({ resetStepper }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    navigate('/');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const offset = 80;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menu__open');
    } else {
      document.body.classList.remove('menu__open');
    }

    return () => {
      document.body.classList.remove('menu__open');
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);

      const sections = ['home', 'features', 'benefits', 'pricing', 'contact'];
      const scrollPosition = window.scrollY + offset;

      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionHeight = sectionElement.clientHeight;

          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogoClick = () => {
    console.log('Logo clicked, resetting stepper.');
    resetStepper(); // Reset the stepper
    setTimeout(() => {
      navigate('/product'); // Wait for the state to update before navigating
    }, 0);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition - offset,
        behavior: 'smooth',
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`header ${isLoggedIn ? 'header__logged' : ''} ${
        isScrolled ? 'header__scrolled' : ''
      }`}
    >
      <Container>
        <Row
          className={`align-items-center ${
            isLoggedIn ? 'justify-content-between' : ''
          }`}
        >
          <Col lg={3} sm={4} className="hdr__col">
            <Navbar.Brand onClick={handleLogoClick}>
              <img src={appLogo} alt="App Logo" />
            </Navbar.Brand>
          </Col>

          {location.pathname === '/' && (
            <Col lg={6} sm={4} className="hdr__col">
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={toggleMenu}
              >
                <img src={humburger} alt="Menu Icon" className="menu__icon" />
                <img src={closeIcon} alt="Close Icon" className="close__icon" />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  {['home', 'features', 'benefits', 'pricing', 'contact'].map(
                    (section) => (
                      <Nav.Item key={section}>
                        <Link
                          className={`nav-link ${
                            activeSection === section ? 'active' : ''
                          }`}
                          to="#"
                          onClick={() => scrollToSection(section)}
                        >
                          {section.charAt(0).toUpperCase() + section.slice(1)}
                        </Link>
                      </Nav.Item>
                    )
                  )}
                </Nav>
              </Navbar.Collapse>
            </Col>
          )}

          <Col lg={3} sm={4} className="d-none d-lg-block">
            <div className="header_actions">
              {location.pathname === '/' && (
                <Link to="login" className="btn btn-primary">
                  Login
                </Link>
              )}
              {!isLoggedIn && (
                <Link
                  to="/"
                  className="btn btn-light"
                  onClick={() => scrollToSection('pricing')}
                >
                  Get started
                </Link>
              )}
            </div>
          </Col>

          {isLoggedIn && location.pathname === '/product' && (
            <Col sm={3} className="hdr__col">
              <div className="header__profile">
                <div className="credit d-none d-lg-block">
                  Credits Remaining: 10
                </div>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="pfl__icon">
                      <img src={profileIcon} alt="Profile Icon" />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    <div className="pfl__info">
                      <span className="name">Olivia Rhye</span>
                      <small className="email">olivia@example.com</small>
                    </div>
                    <Dropdown.Item href="#/action-1">
                      View profile
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Credit left <span className="count">50</span>
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#/setting">Setting</Dropdown.Item> */}
                    <Dropdown.Item href="#/logout" onClick={handleLogout}>
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </Navbar>
  );
}

export default Header;
