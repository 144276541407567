import React from 'react';
import './WhyNeed.scss';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../GlobalComponents/SectionHeading/Components/Description';
import { Col, Container, Row } from 'react-bootstrap';
import WhyNeedCard from './Components/WhyNeedCard/WhyNeedCard';

function WhyNeed() {
  return (
    <>
      <section className="cmn__section why__section" id="features">
        <SectionHeading>
          <Container>
            <Heading>Why You Need This</Heading>
            <Description>
              The most effective way to make iterations of your VSL is by
              changing three key elements:
            </Description>
          </Container>
        </SectionHeading>

        <WhyNeedCard />

        <div className="info__text">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10}>
                <p>
                  The problem? Doing all this manually takes days—sometimes
                  weeks—especially when working with video editors. But with
                  VSLOptimizer.io, you can create brand-new, attention-grabbing
                  versions of your VSL in just minutes. Imagine turning out 20+
                  fresh VSLs every month, all with minimal effort.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default WhyNeed;
