import './assets/sass/style.scss';
import './App.css';
import Home from './pages/Home/Home';
import Header from './Header/Header';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Login from './pages/Login/Login';
import { useEffect, useState } from 'react';
import Layout from './Layout';
import Product from './pages/Product/Product';

// App Component
function App() {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);

  const resetStepper = () => {
    setCurrentStep(0);
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (isLoggedIn) {
      document.body.classList.add('logged-in');
    } else {
      document.body.classList.remove('logged-in');
    }
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== '/login' && <Header resetStepper={resetStepper} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/product"
          element={
            <Product
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
      {/* {location.pathname === '/' && <Footer />} */}
    </>
  );
}

function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Root;
