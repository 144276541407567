import React from 'react';
import logo from '../../assets/images/logo-colored.svg';
import './LoadingScreen.scss';

function LoadingScreen() {
  return (
    <>
      <div className="loading__wrapper">
        <div className="loading__blk">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="loading__body">
            <p>Processing Videos</p>
          </div>
          <div className="loading__footer">
            <div className="btn btn-outline-danger w-100">Cancel</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoadingScreen;
