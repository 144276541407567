import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import videoScenesIcon from '../../../../../../assets/images/users-round.svg';
import voiceIcon from '../../../../../../assets/images/send.svg';
import musicIcon from '../../../../../../assets/images/workflow.svg';
import './HowWorksCard.scss';
import SectionHeading from '../../../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../../../GlobalComponents/SectionHeading/Components/Description';

function HowWorksCard() {
  const HowWorkArr = [
    {
      title: '1. Scene Changing:',
      desc: 'Upload your VSL, hit shift, and click the scene you want to replace. Drop in the new footage, and do this for as many frames as you like.',
    },
    {
      title: '2. Voiceover Change:',
      desc: 'Easily swap voiceovers with Eleven Labs AI. Input your API key and voice ID, and VSLOptimizer.io will align the new voice to your video, adjusting scene lengths for a perfect fit.',
    },
    {
      title: '3. Background Music Changing:',
      desc: 'Upload your MP3s and decide exactly when they should play. Add as many background tracks as you want to give your VSL the perfect atmosphere.',
    },
  ];

  return (
    <>
      <div className="how__listing">
        <Container>
          <Row className="justify-content-center">
            <Col sm={10}>
              <ul className="list-unstyled how__grid">
                {HowWorkArr.map((item, index) => {
                  return (
                    <li key={index} className="how__card">
                      <div className="thumb"></div>
                      <div className="content">
                        <SectionHeading className="text-start section__heading__sm">
                          <Heading>{item.title}</Heading>
                          <Description>{item.desc}</Description>
                        </SectionHeading>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HowWorksCard;
