import React from 'react';
import './CtaSection.scss';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import { Col, Container, Row } from 'react-bootstrap';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../GlobalComponents/SectionHeading/Components/Description';
import { Link } from 'react-router-dom';
import leftArrow from '../../../../assets/images/left-arrow.svg';

function CtaSection() {
  return (
    <>
      <section className="cmn__section cta__section">
        <SectionHeading>
          <Container>
            <Row className="justify-content-center">
              <Col sm={8}>
                <Heading>Join the VSLOptimizer.io Revolution</Heading>
                <Description>
                  Start optimizing your VSLs now. Get started with
                  VSLOptimizer.io and create unlimited new opportunities with
                  your winning VSLs!
                </Description>
              </Col>
            </Row>
          </Container>
        </SectionHeading>

        <div className="cta__row">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10}>
                <div className="cta__blk">
                  <p className="desc">
                    Sign up now and experience the SceneSwitcher.io
                    <br /> difference for yourself. Your ROI will thank you!
                  </p>

                  <Link to="" className="btn btn-outline-primary">
                    Get started
                    <i className="icon">
                      <img src={leftArrow} alt="" />
                    </i>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default CtaSection;
