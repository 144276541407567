import React from 'react';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import backArrow from '../../../../assets/images/back-btn.svg';
import download from '../../../../assets/images/download.svg';
import { Button } from 'react-bootstrap';

function Download({ handleNext, handleBack }) {
  return (
    <>
      <div className="step__form__center download__step">
        <div className="step__card">
          <SectionHeading className="section__heading__sm back__heading mb-5">
            <span className="back__btn" onClick={handleBack}>
              <img src={backArrow} alt="Back" />
            </span>
            <Heading>Video Has Optimized Successfully</Heading>
          </SectionHeading>

          <div className="download__video"></div>

          <div className="step__buttons">
            <Button
              className="btn btn-primary has__arw w-100"
              onClick={handleNext}
            >
              Download Processed Video
              <i className="arw__icon">
                <img src={download} alt="" />
              </i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;
