import React from 'react';
import Hero from './Components/Hero/Hero';
import PownerSection from './Components/PownerSection/PownerSection';
import WhyNeed from './Components/WhyNeed/WhyNeed';
import HowWorks from './Components/HowWorks/HowWorks';
import ChangeExamples from './Components/ChangeExamples/ChangeExamples';
import './Home.scss';
import Pricing from './Components/Pricing/Pricing';
import Contact from './Components/Contact/Contact';
import CtaSection from './Components/CtaSection/CtaSection';

import FooterNew from '../../FooterNew/FooterNew';

function Home() {
  return (
    <>
      <div className="middle">
        <Hero />
        <PownerSection />
        <WhyNeed />
        <HowWorks />
        <ChangeExamples headingText="Scene Change Examples" />
        <ChangeExamples
          headingText="Voice Change Examples"
          className="example__section__rev"
        />
        <ChangeExamples headingText="Background Change Examples" />
        <Pricing />
        <Contact />
        <CtaSection />
      </div>
      <FooterNew />
    </>
  );
}

export default Home;
