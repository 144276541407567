import React from 'react';
import './HowWorks.scss';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../GlobalComponents/SectionHeading/Components/Description';
import { Col, Container, Row } from 'react-bootstrap';
import HowWorksCard from './Components/HowWorkCard/HowWorksCard';

function HowWorks() {
  return (
    <>
      <section className="cmn__section how__work__section" id="benefits">
        <SectionHeading>
          <Container>
            <Heading>How It Works</Heading>
            <Description>
              Our 3-step process is designed for speed and simplicity:
            </Description>
          </Container>
        </SectionHeading>

        <HowWorksCard />

        <div className="info__text">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10}>
                <p>
                  The Result?
                  <br />A completely refreshed video that feels new to your
                  audience—without spending days on
                  <br /> revisions. You'll have your updated VSL ready in as
                  little as 15 minutes!
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default HowWorks;
