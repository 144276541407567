import React from 'react';
import './PownerSection.scss';
import { Col, Container, Row } from 'react-bootstrap';
import SectionHeading from '../../../../GlobalComponents/SectionHeading/SectionHeading';
import Heading from '../../../../GlobalComponents/SectionHeading/Components/Heading';
import Description from '../../../../GlobalComponents/SectionHeading/Components/Description';
import variationImg from '../../../../assets/images/variation.jpg';

function PownerSection() {
  const variationArr = [
    {
      title: 'Variation 1',
      img: variationImg,
    },
    {
      title: 'Variation 2',
      img: variationImg,
    },
    {
      title: 'Variation 3',
      img: variationImg,
    },
  ];

  return (
    <>
      <section className="power__section">
        <SectionHeading className="text-center">
          <Container>
            <Row className="justify-content-center">
              <Col sm={9}>
                <Heading>The Power Of VSLOptimiser.io</Heading>
                <Description>
                  See how we turned this fatigued ad from barely breaking even
                  to a 2-3 ROAS by simply changing the scenes, and the the
                  voice-over while keeping script exactly the same.
                </Description>
              </Col>
            </Row>
          </Container>
        </SectionHeading>

        <div className="variation__section">
          <div className="result__row">
            <Container>
              <Row className="justify-content-center">
                <Col sm={6}>
                  <div className="variation__card">
                    <SectionHeading className="text-center section__heading__sm">
                      <Heading>Original video</Heading>
                    </SectionHeading>
                    <div className="thumb">
                      <img src={variationImg} alt="" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="variation__row">
            <Container>
              <Row className="justify-content-center">
                {variationArr.map((item, index) => {
                  return (
                    <Col sm={4} key={index}>
                      <div className="variation__card">
                        <SectionHeading className="text-center section__heading__sm">
                          <Heading>{item.title}</Heading>
                        </SectionHeading>
                        <div className="thumb">
                          <img src={item.img} alt="" />
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
}

export default PownerSection;
